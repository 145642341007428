import { makeAutoObservable } from "mobx";
import cryptoJS from "crypto-js/md5"

class hashController {
    constructor() {
        makeAutoObservable(this)
    }

    hashKey = "7?rD}0xZR~41X%#Yn6mC"

    setHash(params) {
        let string = "";
        for(let item of params.entries()) {
            string = string + "-" + item[1]
        }
        string = string + this.hashKey
        string = string.slice(1)

        return cryptoJS(string).toString()
    }

    setHashDelete(items) {
        let string = "";
        for(let item in items) {
            string = string + "-" + items[item]
        }
        string = string + this.hashKey
        string = string.slice(1)

        return cryptoJS(string).toString()
    }
}

export default new hashController()